<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>
        <v-col cols="12" md="7">
          <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
            @change="btnsearch"
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        item-key="application_id"
        :search="search"
        show-select
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <template v-slot:top>
          <v-card style="position: sticky; top: 0px; z-index: 5" flat>
            <v-row class="mt-2 mb-2">
              <v-col cols="12" md="8">
                <div v-if="TDselected.length > 0">
                  <v-row>
                   
                    <v-col cols="12" md="2">
                      <v-btn
                        medium
                        color="error"
                        @click="btnsave"
                        class="fullwidth"
                      >
                      <v-icon left>mdi-account-box-multiple</v-icon>
                        Save Image
                      </v-btn>
                    </v-col>


<v-col cols="12" md="2" class="ml-5">
                     <export-excel
            v-if="TDselected.length > 0"
            :data="TDselected"
            type="button"
            

          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >

             <!-- <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD PIC1</v-btn
            >

             <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD PIC2</v-btn
            > -->
          </export-excel>
                    </v-col>

                    <v-col cols="12" md="5" class="ml-3" v-if="Selstatus=='3'">
                    <v-btn color="primary" @click="btnprintmark"><v-icon left>mdi-content-save-all</v-icon>Print Mark</v-btn>
                    </v-col>

                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  dense
                  solo-inverted
                  flat
                  label="Load"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <!-- @click="BtnApprove(index)" -->
        <template v-slot:item.application_id="{ item }">
          <tr>
            <!-- <td>
            <v-checkbox
              v-model="chkselected"
              :value="item.application_id"
              @click="chkclick"
            ></v-checkbox>
          </td> -->
            <td style="text-align: left; width: 100px">
              {{ item.application_id }}
            </td>

            <td style="text-align: left; width: 65px">
              <img
                :src="$apiPath + item.picHeadShot"
                height="60px"
                width="60px"
                style="border-radius: 10px; border: 1px solid #ccc"
                type="button"
              />
            </td>
            <!-- <td>{{ item.fname }} {{item.lname}}</td>
          <td>{{ item.jobtype_name }}</td>
          <td>{{ item.print_text }}</td>
<td>{{ item.status_named }}</td>-->
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

   

    <v-snackbar v-model="snackbar" timeout="1000" top :color="snackbarcolor">
      {{ snackbartext }}
    </v-snackbar>

    <v-overlay :absolute="absolute" :value="overlay" style="z-index: 9000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";


import JSZip from "jszip";
import saveAs from "save-as";
import JSZipUtils from "jszip-utils";
export default {
  components: {
   
  },
  data() {
    return {
      absolute: true,
      btnnextdisable: false,
      btnpredisable: false,
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",
      StatusMessage: "",
      Selstatus: "8",
      Status: [
 {
          status_id: 0,
          status_name: "All",
          status: "O",
          status_named: "All",
        },

        {
          status_id: 8,
          status_name: "Pending",
          status: "O",
          status_named: "Pending",
        },
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },
        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        {
          status_id: 7,
          status_name: "Print",
          status: "O",
          status_named: "Printed",
        },
      ],
      SelstatusForUpade: "",
      StatusForUpdate: [
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },

        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        // {
        //   status_id: 8,
        //   status_name: "Print",
        //   status: "O",
        //   status_named: "Printed",
        // },
      ],

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
        uflag: "0",
        FlagCompanyName: "",
        FlagEmail: "",
      },

    
      postBulkUpdate: {
        application_id: [],
        event_id: "",
        venue_id: "",
      },
      headers: [
        {
          text: "ID",
          align: "left",
          value: "application_id",
          class: "blue lighten-5",
          sortable: false,
        },

         {
          text: "AppId",
          value: "app_id",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Name",
          value: "name",
          align: "left",
          class: "blue lighten-5",
        },

 {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },
        

        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "Card",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Zone",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "UniqueID",
          value: "mName",
          align: "left",
          class: "blue lighten-5",
        },

        

       
       

        
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Photo Download");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-plus-outline");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
   // this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    if (sessionStorage.COMPANYID) {
      this.SelCompany = parseInt(sessionStorage.COMPANYID);
      this.Selstatus = parseInt(sessionStorage.STATUSID);
     // this.btnsearch();
    }
  },
  watch: {
    TDselected: function () {
      if (this.TDselected.length <= 0) {
        this.SelstatusForUpade = "";
        this.SelReasonForUpdate = "";
        this.SelCardTypeForUpdate = "";
      }
    },
    SelstatusForUpade: function () {
      this.SelReasonForUpdate = "";
      this.SelCardTypeForUpdate = "";
    },
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },
   
    getreson: function () {
      // alert(this.SelstatusForUpade);
      this.bindReson(this.SelstatusForUpade);
      this.strcheckatatus = this.SelstatusForUpade;
    },

   

    btnclosesingleapp:function(){
       this.Reload(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.SelCompany,
          this.Selstatus
        );
      this.isViewAppOpened = false;

    },

    BtnApprove: function (ID, status_ID) {
      this.cAppIndex = ID - 1;
      //alert(this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId + "/" + this.desserts[this.cAppIndex].application_id + "/" + this.desserts[this.cAppIndex].vendor_id);
      this.binddata(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.desserts[this.cAppIndex].vendor_id,
        this.desserts[this.cAppIndex].application_id
      );

      this.cApp = this.desserts[this.cAppIndex];
      // this.cApp.dob = this.cApp.dob.slice(0, 10);
      this.appstatus = status_ID;
      sessionStorage.COMPANYID = this.desserts[this.cAppIndex].vendor_id;
      this.isViewAppOpened = true;
      this.btnnextdiaable();
      // this.btnprediaable();
    },

    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
      
    },

    btnprintmark: async function()
    {
      this.postBulkUpdate.application_id=[];
      this.TDselected.forEach((el)=>{
        this.postBulkUpdate.application_id.push(el.application_id);
      });
      this.postBulkUpdate.event_id=this.$store.state.eventInfo.EventId;
      this.postBulkUpdate.venue_id=this.$store.state.eventInfo.VenueId;
      this.overlay=true;
await this.$axios
.post("Print/successPrePrint",this.postBulkUpdate)
.then((res)=>{
  if(res.data.status=="Success"){
    this.showSnackbar("success", "Print mark success !");
    this.Reload(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.SelCompany,
          this.Selstatus
        );
this.overlay=false;

  }
})
.catch()
.finally();

    },

    btnsearch: function () {
      this.TDselected = [];
      this.desserts = [];
      if (this.SelCompany.length <= 0) {
        this.showSnackbar("error", "Select Company !");
      } else if (this.Selstatus.length <= 0) {
        this.showSnackbar("error", "Select Card !");
      } else {
        this.desserts = [];
        this.chkselected = [];
        this.appstatus = this.Selstatus;
        this.Reload(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.SelCompany,
          this.Selstatus
        );
        this.bindReson(this.Selstatus);
      }
    },


    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get("vendor/GetByEventId/" + EventID + "/" + VenueID + "/" +  this.$store.state.eventInfo.EventAdminID)
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
this.Company.splice(0,0,{company_id: "0", company_name: "All" });
            
          }
        });
    },

    bindCartype: async function (event_id) {
      await this.$axios
        .get("Cards/GetByEventId/" + event_id)
        .then((res) => {
          this.CardTypeForUpdate = res.data.result;
        });
    },

    bindStatus: async function () {
      await this.$axios
        .get("Status")
        .then((res) => {
          this.Status = res.data.result;
          this.StatusForUpdate = res.data.result;
        });
    },

    bindReson: async function (StatusID) {
      await this.$axios
        .get("Reason/GetByStatusID/" + StatusID)
        .then((res) => {
          this.ResonForUpdate = res.data.result;
        });
    },

    Reload: async function (event_id, venue_id, company_id, status) {
      this.loadingstatus = true;
      console.log("event_id:" + event_id + "venue_id:" + venue_id + "company_id:" + company_id + "status:" + status + "EventAdminID:" + this.$store.state.eventInfo.EventAdminID);
      await this.$axios
        .get(
            "Application/PrePrintAppData/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            company_id +
            "/" +
            status + "/" + this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          console.log(res);
          this.desserts = res.data.result;
          console.log(res.data.result);
          this.loadingstatus = false;
          this.TDselected = [];
        })
        .catch()
        .finally();
    },
  

    btnsave:  function () {
this.overlay=true;
  const urls = [];
      this.TDselected.forEach((el) => {
        this.postBulkUpdate.application_id.push(el.picHeadShot);

 urls.push(this.$apiPath + el.picHeadShot.charAt(0).toUpperCase() + el.picHeadShot.substring(1)
        );

      });


      const zip = new JSZip();
      let count = 0;
      const zipFilename = "MyData.zip";
      urls.forEach(async function (url) {
        const urlArr = url.split("/");
        const filename = urlArr[urlArr.length - 1];
        try {
          const file = await JSZipUtils.getBinaryContent(url);
          zip.file(filename, file, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
              
            });
          }
          
        }
        catch{console.log('Error')}
      });
this.overlay=false;
      
    },

  
  },
};
</script>

<style>
</style>
